<i18n src="@/i18n/backoffice/account/form.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<Page
		:title="$t('account.title')"
		icon="fa fa-user text-turquoise"
		:loading="isLoading">

		<!-- Action !-->
		<template v-slot:after-title>
			<div class="ml-auto flex space-x-2">
				<!-- Portal !-->
				<portal-target name="after-title" />
			</div>
		</template>
		<!-- Tabs !-->
		<div class="flex items-center justify-between">
			<Tabs :itens="tabs" v-model="currentTab" />
		</div>
		<TabContainers v-model="currentTab">
			<!-- General !-->
			<GeneralForm />
			<!-- Security !-->
			<SecurityForm />
			<!-- Notifications !-->
			<NotificationsForm />
		</TabContainers>
		<!-- / Tabs !-->
	</Page>
</template>

<script>
import PageMixin from "./mixins/form.js"

import Page from "@/components/Page.vue"
import Tabs from "@/components/Tabs.vue"
import TabContainers from "@/components/TabContainers.vue"
import SaveButton from "@/components/SaveButton.vue"

import GeneralForm from "./partials/GeneralForm.vue"
import SecurityForm from "./partials/SecurityForm.vue"
import NotificationsForm from "./partials/NotificationsForm.vue"

export default {
	name: "FormPage",
	mixins: [PageMixin],
	components: {
		Page,
		GeneralForm,
		SecurityForm,
		NotificationsForm,
		Tabs,
		TabContainers,
		SaveButton
	},
	data() {
		return {
			currentTab: 0,
			tabs: [
				this.$t("account.tabs.general"),
				this.$t("account.tabs.security")
				// this.$t('account.tabs.activitylog'),
				// this.$t('account.tabs.notifications')
			]
		}
	},
	mounted() {
		this.init()
	}
}
</script>
