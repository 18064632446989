<i18n src="@/i18n/components/ErrorBag.json"></i18n>
<template>
	<transition v-on:enter="tEnter" v-on:leave="tLeave" :css="false">
		<!--        <div v-if="validation && validation.error" class="error_bag">-->
		<div class="error_bag">
			<template v-for="(message, index) in messages">
				<p :key="`message-${index}`" class="mt-2 text-sm text-red-500 whitespace-nowrap">
					<slot>{{ message }}</slot>
				</p>
			</template>
		</div>
	</transition>
</template>

<script>
import gsap from "gsap"

export default {
	name: "ErrorBag",
	props: {
		placeholder: String,
		validation: Object,
		multiple: Boolean,
		custom: Array
	},
	computed: {
		messages() {

			if (!this.validation?.$error) return []

			const msgs = []
			for (let i = 0; i < this.validation?.$errors?.length; i++) {
				let error = this.validation.$errors[i]

				if (error.$validator === "required") {
					msgs.push(this.$t("error_bag.required_field"))
				}

				if (error.$validator === "minLength") {
					if (this.validation.isList) {
						msgs.push(this.$t("error_bag.min_length_list", [this.validation.$params.minLength.min]))
					} else {
						msgs.push(this.$t("error_bag.min_length", [this.validation.$params.minLength.min]))
					}
				}

				if (error.$validator === "maxLength") {
					if (this.validation.isList) {
						msgs.push(this.$t("error_bag.max_length_list", [this.validation.$params.maxLength.max]))
					} else {
						msgs.push(this.$t("error_bag.max_length", [this.validation.$params.maxLength.max]))
					}
				}

				if (error.$validator === "email") {
					msgs.push(this.$t("error_bag.invalid_email"))
				}

				if (error.$validator === "sameAs") {
					if (this.validation.sameAs.$params.eq === "password") {
						msgs.push(this.$t("error_bag.password_config"))
					} else {
						msgs.push(this.$t("error_bag.value_same_as", [this.placeholder ?? this.validation.sameAs.$params.eq]))
					}
				}

				if (error.$validator === "validCNPJ") {
					msgs.push(this.$t("error_bag.invalid_cnpj"))
				}

				if (error.$validator === "validCPF") {
					msgs.push(this.$t("error_bag.invalid_cpf"))
				}

				if (error.$validator === "dateGtThan18Years") {
					msgs.push(this.$t("error_bag.error.data_invalida"))
				}

				if (error.$validator === "$externalResults") {
					msgs.push(this.$t(error.$message))
				}
			}

			return msgs.length ? this.multiple ? msgs : [msgs[0]] : msgs
		},
		hasMessages() {
			return this.messages?.length > 0
		}
	},
	methods: {

		tEnter(el, done) {
			gsap.fromTo(el, { opacity: 0, top: 0 }, { opacity: 1, duration: .15, onComplete: done })
		},
		tLeave(el, done) {
			gsap.to(el, { opacity: 0, duration: .15, onComplete: done })
		}
	}
}
</script>
