<i18n src="@/i18n/components/CreateNewButton.json"></i18n>
<template>
	<router-link tag="button" :to="to"
				 class="items-center pl-4 pr-6 py-2
				 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500
				 transition-all duration-200
				 hover:bg-blue-700
				 active:transform-gpu active:scale-95">
		<i class="mr-2" :class="icon"></i>
		<slot>{{ $t("create_new_button.label") }}</slot>
	</router-link>
</template>
<script>
export default {
	name: "CreateButton",
	props: {
		to: [Object, String],
		icon: {
			type: String,
			default: "fa fa-plus text-xs"
		}
	}
}
</script>