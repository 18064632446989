import Account from "@/API/Account.js"
import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import HandleRequestErrors from "@/mixins/handle_request_errors.js"

import ValidationMixin from "@/mixins/validation.js"
import { email, minLength, required, sameAs } from "@vuelidate/validators"

import { get, sync } from "vuex-pathify"

export default {
	mixins: [LoadingMixin, SystemMixin, ValidationMixin, HandleRequestErrors],
	computed: {
		...sync("Account", ["form"])
	},
	validations() {
		let passwordForm = {
			password: { required },
			password_confirmation: {
				required,
				sameAs: sameAs(this.passwordForm.password)
			}
		}
		return { passwordForm }
	},
	methods: {
		/**
		 * Action validate
		 * @returns {boolean}
		 */
		validate() {
			this.v$.$touch()

			console.debug("Account/Form.GeneralForm@validate: Validating data.")

			return !this.v$.$invalid
		},
		/**
		 * Save form
		 * @returns {Promise<boolean>}
		 */
		async save() {
			if (!await this.validate()) return false

			const data = copy_obj(this.passwordForm)

			console.debug("Account/Form/SecurityForm@save: Saving data.")

			this.loading()

			const [err, res] = await Account.updatePassword(data)

			this.loaded()

			if (err) {
				console.error("Account/Form/SecurityForm@save: Error saving data.", err)
				return this.$alertError(this.handleRequestError(err))
			}

			this.clear()

			console.debug("Account/Form/SecurityForm@save: Data saved.")

			return true
		},
		clear() {
			this.v$.$reset()
			this.passwordForm = {
				password: null,
				password_confirmation: null
			}
		}
	}
}
