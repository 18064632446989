<i18n src="@/i18n/shared/account/security_form.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<div v-if="isActive">
		<!-- Action !-->
		<portal to="after-title">
			<SaveButton class="ml-auto" @click="handleSave" />
		</portal>
		<!-- Access Data !-->
		<Card :title="$t('account.security.access_data')">
			<FormGroup>
				<!-- Password !-->
				<FormLine :label="$t('account.security.password')">
					<FormInput type="password" v-model="passwordForm.password" :validation="v$.passwordForm.password" class="max-w-xs" />
				</FormLine>
				<!-- Confirm Password !-->
				<FormLine :label="$t('account.security.password_confirmation')">
					<FormInput type="password" v-model="passwordForm.password_confirmation" :validation="v$.passwordForm.password_confirmation" class="max-w-xs" />
				</FormLine>
			</FormGroup>
		</Card>
	</div>
</template>


<script>
import Mixin from "./mixins/security_form.js"

import Card from "@/components/Card.vue"
import FormGroup from "@/components/FormGroup.vue"
import FormLine from "@/components/FormLine.vue"
import FormInput from "@/components/FormInput.vue"
import SaveButton from "@/components/SaveButton.vue"

export default {
	name: "SecurityForm",
	mixins: [Mixin],
	components: {
		SaveButton,
		FormInput,
		FormLine,
		Card,
		FormGroup
	},
	data() {
		return {
			isActive: false,
			passwordForm: {
				password: null,
				password_confirmation: null
			}
		}
	},
	methods: {
		/**
		 * Handle Save
		 */
		async handleSave() {
			if (!await this.save()) return false

			this.$alertFormSaved()
		}
	}
}
</script>