import { render, staticRenderFns } from "./SecurityForm.vue?vue&type=template&id=42b23430&"
import script from "./SecurityForm.vue?vue&type=script&lang=js&"
export * from "./SecurityForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/shared/account/security_form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fyurinascimento%2FDocuments%2FWork%2Ftristar%2Ftristar-courier-frontend%2Fsrc%2Fpages%2Fshared%2Faccount%2Fpartials%2FSecurityForm.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/form.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2FUsers%2Fyurinascimento%2FDocuments%2FWork%2Ftristar%2Ftristar-courier-frontend%2Fsrc%2Fpages%2Fshared%2Faccount%2Fpartials%2FSecurityForm.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports