import Http from "@/config/axios.js"
import { to } from "await-to-js"

export default {
	async get() {
		return to(Http.get(window.API_PREFIX + `/account`))
	},
	async update(id, data) {
		data.append("_method", "patch")
		return to(Http.post(window.API_PREFIX + `/account/${id}`, data))
	},
	async updateDevice(id, data) {
		data.append("_method", "patch")
		return to(Http.post(window.API_PREFIX + `/account-device/${id}`, data))
	},
	async deleteDevice(id) {
		return to(Http.post(window.API_PREFIX + `/account-device/${id}`, { "_method": "delete" }))
	},
	async updatePassword(data) {
		return to(Http.post(window.API_PREFIX + `/account-password`, data))
	}

}