<i18n src="@/i18n/components/SaveButton.json"></i18n>
<template>
    <button
        class="inline-flex items-center py-2 pl-3 pr-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white
        bg-green-600 hover:bg-green-700 focus:outline-none
		transition-all duration-200 active:scale-95"
        v-on="$listeners">
        <i class="fa fa-save text-xs"></i>
        <span class="ml-2.5 text-sm font-medium">
            <slot>{{ $t('save_button.label') }}</slot>
        </span>
    </button>
</template>

<script>
export default {
    name: "SaveButton"
}
</script>