<i18n src="@/i18n/backoffice/account/general_form.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<div v-if="isActive">
		<!-- Action !-->
		<portal to="after-title">
			<SaveButton class="ml-auto" @click="handleSave" />
		</portal>
		<Card :title="$t('account.personal_info')">
			<FormGroup>
				<!-- Photo !-->
				<FormLine :label="$t('account.photo')">
					<div class="flex items-center">
						<input ref="picture" type="file" class="hidden" @change="handlePictureSelected" />
						<ProfileImage size="16" :src="form.picture_url" :letters="form.name" no-shadow />
						<div class="ml-5 rounded-md shadow-sm">
							<button type="button"
									class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
									@click="handleSelectPicture">
								{{ $t("account.change") }}
							</button>
						</div>
					</div>
				</FormLine>
				<!-- Name !-->
				<FormLine :label="$t('account.name')" divider>
					<FormInput v-model="form.name" :validation="v$.form.name" class="max-w-md" />
				</FormLine>
				<!-- Document !-->
				<FormLine :label="$t('account.document')">
					<div class="flex space-x-2 max-w-md">
						<FormSelect :options="documentTypes" :default-option="false" v-model="form.document_type" :validation="v$.form.document_type"
									class="w-38 flex-shrink-0" />
						<FormInput v-model="form.document" :validation="v$.form.document" class="flex-grow" />
					</div>
				</FormLine>
				<!-- Birthday !-->
				<FormLine :label="$t('account.birthday')">
					<FormDatePicker v-model="form.birthday" :validation="v$.form.birthday" class="w-48" />
				</FormLine>
				<!-- Phone !-->
				<FormLine :label="$t('account.phone')">
					<FormPhone v-model="form.phone" :validation="v$.form.phone" class="max-w-md" />
				</FormLine>
			</FormGroup>
		</Card>
		<!-- Formas de contato !-->
		<Card :title="$t('account.configurations')" class="mt-4">
			<FormGroup>
				<!-- Language !-->
				<FormLine :label="$t('account.language')">
					<FormLocales v-model="form.language_id" :validation="v$.form.language_id" class="max-w-md" />
				</FormLine>
				<!-- Weight Unit !-->
				<FormLine :label="$t('account.prefered_weight_unit')">
					<FormWeightUnits v-model="form.prefered_weight_unit" :validation="v$.form.prefered_weight_unit" class="max-w-md" />
				</FormLine>
				<!-- Size Unit !-->
				<FormLine :label="$t('account.prefered_size_unit')">
					<FormSizeUnits v-model="form.prefered_size_unit" :validation="v$.form.prefered_size_unit" class="max-w-md" />
				</FormLine>
				<!-- Timezone !-->
				<FormLine :label="$t('account.prefered_timezone')">
					<FormTimezone :value="form.preferred_timezone_id" @input:object="form.preferred_timezone = $event;form.preferred_timezone_id = $event.id"
								  :validation="v$.form.preferred_timezone" class="max-w-md" />
				</FormLine>
			</FormGroup>
		</Card>
		<!-- / Formas de contato !-->
		<PageFooterActions>
			<BackButton />
		</PageFooterActions>
	</div>

</template>

<script>
import PageMixin from "./mixins/general_form.js"

import Card from "@/components/Card.vue"
import FormLine from "@/components/FormLine.vue"
import FormRadio from "@/components/FormRadio.vue"
import FormInput from "@/components/FormInput.vue"
import FormPhone from "@/components/FormPhone.vue"
import FormSelect from "@/components/FormSelect.vue"
import FormDatePicker from "@/components/FormDatePicker.vue"
import ProfileImage from "@/components/ProfileImage.vue"
import FormWeightUnits from "@/components/FormWeightUnits.vue"
import FormSizeUnits from "@/components/FormSizetUnits.vue"
import FormLocales from "@/components/FormLocales.vue"
import FormTimezone from "@/components/FormTimezone.vue"
import FormGroup from "@/components/FormGroup.vue"
import PageFooterActions from "@/components/PageFooterActions.vue"
import BackButton from "@/components/BackButton.vue"
import SaveButton from "@/components/SaveButton.vue"

export default {
	name: "GeneralForm",
	mixins: [PageMixin],
	components: {
		SaveButton,
		BackButton,
		PageFooterActions,
		FormGroup,
		Card,
		FormLine,
		FormInput,
		FormSelect,
		FormRadio,
		FormDatePicker,
		FormPhone,
		ProfileImage,
		FormSizeUnits,
		FormWeightUnits,
		FormLocales,
		FormTimezone
	},
	data() {
		return {
			isActive: false
		}
	},
	methods: {
		/**
		 * Handle Save
		 */
		async handleSave() {
			if (!await this.save()) return false

			this.$alertFormSaved()

			// Detect if locale is changed
			if (this.form.language_id != this.currentLanguage) {
				this.$modalInfo(this.$t("account.locale_changed.title"), this.$t("account.locale_changed.message"), {
					onAction() {
						window.location.reload(true)
					}
				})
			}
		},
		/**
		 * Handle select picture
		 */
		handleSelectPicture() {
			this.$refs.picture.click()
		},
		/**
		 * Handle picture selected
		 * @param evt
		 */
		handlePictureSelected(evt) {
			if (!evt.target.files.length) return
			this.form.picture = evt.target.files[0]
			this.form.picture_url = URL.createObjectURL(evt.target.files[0])
		}
	}
}
</script>
